<template>
  <div>
    <div class="text-right mb-1">
      <b-button
        variant="primary"
        pill
        @click="createVisaAssistance"
        v-if="hasPermission('create_visa_assistance')"
      >
        <feather-icon icon="PlusIcon" class="mr-50" />
        <span class="align-middle">Create</span>
      </b-button>
    </div>

    <b-card>
      <b-table
        responsive="sm"
        :fields="fields"
        :items="visaAssistances"
        details-td-class="m-0 p-0"
        small
        v-if="hasPermission('read_visa_assistance')"
        :per-page="0"
      >
        <template #custom-foot>
          <b-tr variant="light">
            <b-td :colspan="fields.length">
              <span class="font-weight-bolder">
                Total Rows: {{ totalItems }}
              </span>
            </b-td>
          </b-tr>
        </template>
        <template #cell(serial)="row">
        {{ (currentPage - 1) * perPage + row.index + 1 }}
      </template>
      <template #cell(name)="row">
        {{ truncate(row.item.name, 20) }} 
      </template>
        <template #cell(created_at)="row">
          {{ row.item.created_at }}
        </template>
        <template #cell(updated_at)="row">
          {{ row.item.updated_at }}
        </template>
        <template #cell(created_by_data)="row">
          {{
            row.item.created_by
          }}
        </template>
        <template #cell(updated_by_data)="row">
          {{
            row.item.updated_by
          }}
        </template>
        <template #cell(manage)="row">
          <b-button
            variant="info"
            pill
            size="sm"
            class="mr-1"
            @click="editVisaAssistance(row.item)"
            v-if="hasPermission('update_visa_assistance')"
          >
            Edit
          </b-button>
          <b-button
            variant="danger"
            pill
            size="sm"
            @click="removeVisaAssistance(row.item)"
            v-if="hasPermission('delete_visa_assistance')"
          >
            Delete
          </b-button>
        </template>
      </b-table>
      <b-pagination
        size="md"
        :total-rows="totalItems"
        v-model="currentPage"
        :per-page="perPage"
      ></b-pagination>
      <!-- <span class="mt-1 bg1 float-right" >Total Rows: {{ rows }}</span> -->
    </b-card>
    <visa-assistance-create-modal
      @modalClosed="onModalClosed"
      :key="`create-${visaAssistanceCreateModalCount}`"
    />
    <visa-assistance-edit-modal
      :visaAssistance="visaAssistance"
      @modalClosed="onModalClosed"
      :key="`edit-${visaAssistanceEditModalCount}`"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import util from "@/util.js";
import VisaAssistanceCreateModal from "@/components/admin/visaAssistance/VisaAssistanceCreateModal.vue";
import VisaAssistanceEditModal from "@/components/admin/visaAssistance/VisaAssistanceEditModal.vue";

export default {
  components: {
    VisaAssistanceCreateModal,
    VisaAssistanceEditModal,
  },
  data() {
    return {
      fields: [
        { key: "serial", label: "S. No" },
        { key: "name", label: "Name" },
        { key: "amount", label: "Amount" },
        { key: "includes_flight_booking", label: "Booking" },
        { key: "includes_visa_processing", label: "Processing" },
        { key: "created_at", label: "Created At" },
        { key: "created_by_data", label: "Created By" },
        { key: "updated_at", label: "Created At" },
        { key: "updated_by_data", label: "Updated By" },
        { key: "manage", label: "Action" },
      ],
      currentPage: 1,
      perPage: 0,
      totalItems: 0,
      visaAssistances: [],
      visaAssistance: null,
      VisaAssistanceCreateModal: 0,
      VisaAssistanceEditModal: 0,
      visaAssistanceCreateModalCount: 0,
      visaAssistanceEditModalCount: 0,
      nameFilter: "",
    };
  },
  mixins: [util],
  async mounted() {
    await this.fetchPaginatedData();
  },
  methods: {
    ...mapActions({
      getVisaAssistance: "appData/getVisaAssistance",
      deleteVisaAssistance: "appData/deleteVisaAssistance",
    }),
    async filter() {
      this.currentPage = 1;
      await this.fetchPaginatedData();
    },
    async fetchPaginatedData() {
      try {
        let tempParams = {
          pageNumber: this.currentPage,
        };
        if (this.nameFilter) {
          tempParams["name"] = this.nameFilter;
        }
        const res = await this.getVisaAssistance(tempParams);
        this.visaAssistances = res.data.results;
        this.totalItems = res.data.count;
        this.perPage = res.data.per_page;
      } catch (error) {
        this.displayError(error);
      }
    },
    createVisaAssistance() {
      this.visaAssistanceCreateModalCount += 1;
      this.$nextTick(() => {
        this.$bvModal.show("visa-assistance-create-modal");
      });
    },
    editVisaAssistance(visaAssistance) {
      this.visaAssistance = visaAssistance;
      this.visaAssistanceEditModalCount += 1;
      this.$nextTick(() => {
        this.$bvModal.show("visa-assistance-edit-modal");
      });
    },
    async removeVisaAssistance(visaAssistance) {
      this.visaAssistance = visaAssistance;

      this.$swal({
        title: "Are you sure?",
        icon: "warning",
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: "Confirm",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger ml-1",
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          try {
            const res = await this.deleteVisaAssistance({
              pk: this.visaAssistance.id,
            });
            if (res.status === 200) {
              this.$swal({
                title: "Visa Assistance deleted successfully",
                icon: "success",
              });
              if (
                (this.totalItems - 1) % this.perPage === 0 &&
                this.currentPage !== 1
              ) {
                this.currentPage -= 1;
              } else {
                await this.fetchPaginatedData();
              }
            }
          } catch (error) {
            this.show = false;
            this.displayError(error);
          }
        } else {
          this.show = false;
        }
      });
    },
    async onModalClosed() {
      await this.fetchPaginatedData();
    },
  },
  computed: {
    ...mapGetters({
      hasPermission: "appData/hasPermission",
    }),
    rows() {
      return this.visaAssistances.length;
    },
  },
  watch: {
    currentPage: async function (val) {
      await this.fetchPaginatedData();
    },
  },
};
</script>

<style>
.bg1 {
  color: red;
  font-weight: normal;
}
</style>

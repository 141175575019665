<template>
  <b-modal
    id="visa-assistance-edit-modal"
    title="Interprator Service Edit Modal"
    centered
    hide-footer
    size="lg"
    @hidden="reset"
    :no-close-on-esc="true"
    :no-close-on-backdrop="true"
  >
    <template #modal-title>
      <h2 class="m-0">Service Edit</h2>
    </template>
    <validation-observer ref="interpratorServiceEditFormValidation">
      <b-form @submit.prevent="submit">
        <b-form-group label-for="name">
          <template #label>
            Service Name <span class="text-danger">*</span>
          </template>
          <b-form-input
            id="name"
            v-model="name"
            v-restrict="regex"
            required
            placeholder="Service Name"
            @keydown="checkMaxLength($event, 'name', 100)"
          />
        </b-form-group>

        <b-form-group label-for="description">
          <template #label> Description </template>
          <b-form-textarea
            id="description"
            v-model="description"
            placeholder="Description"
            rows="3"
            max-rows="6"
          ></b-form-textarea>
        </b-form-group>

        <b-form-group label-for="amount">
          <template #label>
            Amount <span class="text-danger">*</span>
          </template>
          <b-form-input
            id="amount"
            v-model="amount"
            placeholder="Amount"
            required
            type="number"
            min="1"
            step="1"
          />
          <b-form-invalid-feedback v-if="!amountState">
            Please enter a valid positive integer.
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group label-for="includes_flight_booking">
          <template #label>
            Flight Booking <span class="text-danger">*</span>
          </template>
          <b-form-select
            id="includes_flight_booking"
            v-model="includes_flight_booking"
            :options="options"
            required
          ></b-form-select>
        </b-form-group>

        <b-form-group label-for="includes_visa_processing">
          <template #label>
            Visa Processing <span class="text-danger">*</span>
          </template>
          <b-form-select
            id="includes_visa_processing"
            v-model="includes_visa_processing"
            :options="options"
            required
          ></b-form-select>
        </b-form-group>

        <b-form-group label-for="additional_services">
          <template #label> Additional Services </template>
          <b-form-textarea
            id="additional_services"
            v-model="additional_services"
            placeholder="Additional Services"
            rows="3"
            max-rows="6"
          ></b-form-textarea>
        </b-form-group>

        <b-form-group label-for="status">
          <template #label>
            Status <span class="text-danger">*</span>
          </template>
          <b-form-select
            id="status"
            v-model="status"
            :options="options"
            required
          ></b-form-select>
        </b-form-group>

        <!-- Submit Button -->
        <b-form-group class="mt-2 text-right">
          <b-button type="submit" variant="info" pill class="mr-1">
            Update
          </b-button>
        </b-form-group>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import util from "@/util.js";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [util],
  props: {
    visaAssistance: Object,
  },
  data() {
    return {
      required,
      name: "",
      description: "",
      amount: "",
      includes_flight_booking: "",
      includes_visa_processing: "",
      additional_services: "",
      status: "",
      options: [
        { value: "", text: "-- Select --" },
        { value: true, text: "Enable" },
        { value: false, text: "Disable" },
      ],
      regex: /^[a-zA-Z ]*$/,
    };
  },
  async mounted() {
    if (this.visaAssistance) {
      this.name = this.visaAssistance.name;
      this.description = this.visaAssistance.description;
      this.amount = this.visaAssistance.amount;
      this.includes_flight_booking =
        this.visaAssistance.includes_flight_booking;
      this.includes_visa_processing =
        this.visaAssistance.includes_visa_processing;
      this.additional_services = this.visaAssistance.additional_services;
      this.status = this.visaAssistance.status;
    }
  },
  methods: {
    ...mapActions({
      updateVisaAssistance: "appData/updateVisaAssistance",
    }),
    checkMaxLength(event, fieldName, maxLength) {
      if (
        event.target.value.length >= maxLength &&
        event.key !== "Backspace" &&
        event.key !== "Delete"
      ) {
        event.preventDefault();
        this[fieldName] = event.target.value.slice(0, maxLength);
      }
    },
    async submit() {
      try {
        const res = await this.updateVisaAssistance({
          payload: {
            name: this.name,
            description: this.description,
            amount: this.amount,
            includes_flight_booking: this.includes_flight_booking,
            includes_visa_processing: this.includes_visa_processing,
            additional_services: this.additional_services,
            status: this.status,
            updated_by: this.getLoggedInUser.id,
          },
          pk: this.visaAssistance.id,
        });
        if (res.status === 200) {
          this.$swal({
            title: "Visa Assistance updated successfully",
            icon: "success",
          });
          this.reset();
          this.$nextTick(() => {
            this.$bvModal.hide("visa-assistance-edit-modal");
          });
          this.$emit("modalClosed");
        }
      } catch (error) {
        this.displayError(error);
      }
    },
    reset() {
      this.name = "";
      this.description = "";
      this.amount = "";
      this.includes_flight_booking = "";
      this.includes_visa_processing = "";
      this.additional_services = "";
      this.status = "";
    },
  },
  computed: {
    ...mapGetters({ getLoggedInUser: "appData/getLoggedInUser" }),
    amountState() {
      return (
        this.amount !== "" &&
        Number.isInteger(Number(this.amount)) &&
        Number(this.amount) > 0
      );
    },
  },
};
</script>

<style></style>
